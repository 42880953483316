import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Motion_Detection/NavButtons';
import ForumBox from 'components/Motion_Detection/SD_Card_Access/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "SD Card Access - SiteSucker",
  "path": "/Motion_Detection/SD_Card_Access/SiteSucker/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Access the Camera Recordings on the internal SD Card",
  "image": "./MD_SearchThumb_SDCardAccess_SiteSucker.png",
  "social": "/images/Search/MD_SearchThumb_SDCardAccess_SiteSucker.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_MD-SD-Card_white.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='SD Card Access - SiteSucker' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Access the Camera Recordings on the internal SD Card' image='/images/Search/MD_SearchThumb_SDCardAccess_SiteSucker.png' twitter='/images/Search/MD_SearchThumb_SDCardAccess_SiteSucker.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/SD_Karten_Zugriff/SiteSucker/' locationFR='/fr/Motion_Detection/SD_Card_Access/SiteSucker/' crumbLabel="SiteSucker" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "sitesucker---ftp-access-to-the-internal-sd-card",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#sitesucker---ftp-access-to-the-internal-sd-card",
        "aria-label": "sitesucker   ftp access to the internal sd card permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SiteSucker - FTP Access to the internal SD Card`}</h2>
    <p>{`SiteSucker for macOSX is a wget based client that you can use to access your cameras SD card. We recommend you to download the program from `}<Link href="http://www.sitesucker.us/mac/mac.html" target="_blank" rel="noopener noreferrer" mdxType="Link">{`here`}</Link>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "652px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fa95e85e5ee1328288961e1125066b0c/dba9a/SiteSucker_MacOS_Step1_EN.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.95652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAABd0lEQVQoz42RWW7bQBBEef8bxcgBHMWQJdHgIhtch6tFcZW4+wUzUhx/BSbwUN2NmmJzqEWWjuHteHNdHMfF9TylURRj27bqDcPEtCz8IEAIQZplxElClmV4vn87dz+v7Q4vHK0jzqvN89MGfb9VvOh7nn5vFPphj2WZbH49oh8OHG0LyzSwLVOpxLyrFoU+z5bPj23Mw1bwsI35uUuwwxJx6vCzBu+OqtMGN60VnuI2d9MGLy7QIiFwglvAUZS8xg2G944TpIjkRJQWdMNMUXUEUaZm5/pC188kecm5uaq67WfeiwpN3sP1ekE+y9TDOsHHwjj0LPPEPI18rLd+HAY1k8hZ17ZUZck0jly7lqI4ofm+ry54WVe67kLfD0zTzDTfmKVO07/6Sz+OI3VdK03TlDiOZaBHlucsy8IwDMosDd/hr1++KM9zFapFkaA4n1nX9dPwHeb7hhK5zGdgGIZq1bZpKMuSqqrUZ0j9H189spZh8n/8Aa24pFLLdsjpAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fa95e85e5ee1328288961e1125066b0c/e4706/SiteSucker_MacOS_Step1_EN.avif 230w", "/en/static/fa95e85e5ee1328288961e1125066b0c/d1af7/SiteSucker_MacOS_Step1_EN.avif 460w", "/en/static/fa95e85e5ee1328288961e1125066b0c/ab816/SiteSucker_MacOS_Step1_EN.avif 652w"],
              "sizes": "(max-width: 652px) 100vw, 652px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fa95e85e5ee1328288961e1125066b0c/a0b58/SiteSucker_MacOS_Step1_EN.webp 230w", "/en/static/fa95e85e5ee1328288961e1125066b0c/bc10c/SiteSucker_MacOS_Step1_EN.webp 460w", "/en/static/fa95e85e5ee1328288961e1125066b0c/db2ac/SiteSucker_MacOS_Step1_EN.webp 652w"],
              "sizes": "(max-width: 652px) 100vw, 652px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fa95e85e5ee1328288961e1125066b0c/81c8e/SiteSucker_MacOS_Step1_EN.png 230w", "/en/static/fa95e85e5ee1328288961e1125066b0c/08a84/SiteSucker_MacOS_Step1_EN.png 460w", "/en/static/fa95e85e5ee1328288961e1125066b0c/dba9a/SiteSucker_MacOS_Step1_EN.png 652w"],
              "sizes": "(max-width: 652px) 100vw, 652px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fa95e85e5ee1328288961e1125066b0c/dba9a/SiteSucker_MacOS_Step1_EN.png",
              "alt": "SiteSucker - FTP Access to the internal SD Card",
              "title": "SiteSucker - FTP Access to the internal SD Card",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-1",
        "aria-label": "step 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 1`}</h3>
    <p>{`Type in your cameras IP address e.g. 192.168.1.17 and add the sub-directory sd - `}<code><a parentName="p" {...{
          "href": "http://192.168.1.17/sd"
        }}>{`http://192.168.1.17/sd`}</a></code>{`. Then click on Download.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "653px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/500bcef92573518ad25d75f9c05ca693/e7dce/SiteSucker_MacOS_Step2_EN.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.95652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAABqklEQVQozz1R266bMBDk/z+in1BV6nPf2qeek16UADbG2ICxDSQEgpImSjTVbpSDNFrYy+zOkLhaIze/oSqNtm3hnGOEGFHXNbqugxACaZqh8/5ZC4HzMUZYa6ErjaqqoLVGkuUCqtAoZIHtdovdbocsz5kky7LnuxTI85xraZZxjb4JaZp+gPoTukLrkhOlUhiHAaYo4VwLVShIKSGk5EtMZTiWpYZSCrWtEfuIaZoYwzAgca0DgeT2Qw8jFH5+/caN7+8bxmbzixdWlYFSJfd775ngeDzier3idrsxaULJ8+UCek6nE5q6gXcd+0Se0CBdFUJE3/ecn+cZ67pyJB+JcF1PGMfxKdkYy+w0QHFZFjRNAykEWj9AmA7DfMF6BZZ/DxzPD0znB/x0gQ89brcrLyYktbUsl7YR4X6/f5LHCEE/Ii+wlRY7aaCsh256VLVD8B3G/YTDdMT9fv+4PiHzD9PEkg+HA5ORL68FtDWGwNFYC1db/FUBn98GfJfzByFJZ8K2aeC6jmXSECVfIMIXyGvvA3rv8Efv8enHjC9vIzofsCwz9xPpf2oJlbrSBJ+4AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/500bcef92573518ad25d75f9c05ca693/e4706/SiteSucker_MacOS_Step2_EN.avif 230w", "/en/static/500bcef92573518ad25d75f9c05ca693/d1af7/SiteSucker_MacOS_Step2_EN.avif 460w", "/en/static/500bcef92573518ad25d75f9c05ca693/d63e6/SiteSucker_MacOS_Step2_EN.avif 653w"],
              "sizes": "(max-width: 653px) 100vw, 653px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/500bcef92573518ad25d75f9c05ca693/a0b58/SiteSucker_MacOS_Step2_EN.webp 230w", "/en/static/500bcef92573518ad25d75f9c05ca693/bc10c/SiteSucker_MacOS_Step2_EN.webp 460w", "/en/static/500bcef92573518ad25d75f9c05ca693/d0839/SiteSucker_MacOS_Step2_EN.webp 653w"],
              "sizes": "(max-width: 653px) 100vw, 653px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/500bcef92573518ad25d75f9c05ca693/81c8e/SiteSucker_MacOS_Step2_EN.png 230w", "/en/static/500bcef92573518ad25d75f9c05ca693/08a84/SiteSucker_MacOS_Step2_EN.png 460w", "/en/static/500bcef92573518ad25d75f9c05ca693/e7dce/SiteSucker_MacOS_Step2_EN.png 653w"],
              "sizes": "(max-width: 653px) 100vw, 653px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/500bcef92573518ad25d75f9c05ca693/e7dce/SiteSucker_MacOS_Step2_EN.png",
              "alt": "SiteSucker - FTP Access to the internal SD Card",
              "title": "SiteSucker - FTP Access to the internal SD Card",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-2",
        "aria-label": "step 2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 2`}</h3>
    <p>{`Type in your user name and password for the camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "652px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4b9ee035b294c220c83755ca19c98382/dba9a/SiteSucker_MacOS_Step3_EN.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAABg0lEQVQoz42R2W7bMBBF9f9f1qZLXHmRbMciKWuPZW1eJFHyKcgmQftShMDBHQ4uh8OhE/tLPLngIARBECClJBCCKI4JwxAhJZuNh+f7qDAkiiKyPCdJU/I8RypFEAiEPS9wFu6Knbdjv9vy/dsTi8WzZb1a2tzzzx8s3V/4vsfT1y8sXZfd1sf3Nmx9z6rBe1PnGCrWe4W7T3B3Ect9zPqQsjokLHzF6iVGpA0qby0yaxFZY5GWP3mRtcikxImjiDDKiPMzcXYifa0I45z19sDaP7B9UXQ3TVlfbD5KT5ybK5e7Ji0qzu3Nxt1d81rWOGYOt+sVs8ahZ540j3lCjyM8Zhs/HjNDf2foeyY9Wkz+0nXUVcU4DNwuHWV5wlFK2QHP88z1cuV+7xlHzTiO/6I1Wmur7/thGGiaxmqWZSRJYgpK8qJgmib6vrdmY/gM735zUVEUtqgTxxHl+Ww7fDd8Bv3WocE081HweDzaVru2paoq6rq2zzD6P/72mNgUM//xG3f5pJpSBQjyAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4b9ee035b294c220c83755ca19c98382/e4706/SiteSucker_MacOS_Step3_EN.avif 230w", "/en/static/4b9ee035b294c220c83755ca19c98382/d1af7/SiteSucker_MacOS_Step3_EN.avif 460w", "/en/static/4b9ee035b294c220c83755ca19c98382/ab816/SiteSucker_MacOS_Step3_EN.avif 652w"],
              "sizes": "(max-width: 652px) 100vw, 652px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4b9ee035b294c220c83755ca19c98382/a0b58/SiteSucker_MacOS_Step3_EN.webp 230w", "/en/static/4b9ee035b294c220c83755ca19c98382/bc10c/SiteSucker_MacOS_Step3_EN.webp 460w", "/en/static/4b9ee035b294c220c83755ca19c98382/db2ac/SiteSucker_MacOS_Step3_EN.webp 652w"],
              "sizes": "(max-width: 652px) 100vw, 652px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4b9ee035b294c220c83755ca19c98382/81c8e/SiteSucker_MacOS_Step3_EN.png 230w", "/en/static/4b9ee035b294c220c83755ca19c98382/08a84/SiteSucker_MacOS_Step3_EN.png 460w", "/en/static/4b9ee035b294c220c83755ca19c98382/dba9a/SiteSucker_MacOS_Step3_EN.png 652w"],
              "sizes": "(max-width: 652px) 100vw, 652px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4b9ee035b294c220c83755ca19c98382/dba9a/SiteSucker_MacOS_Step3_EN.png",
              "alt": "SiteSucker - FTP Access to the internal SD Card",
              "title": "SiteSucker - FTP Access to the internal SD Card",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-3",
        "aria-label": "step 3 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 3`}</h3>
    <p>{`To access your camera from the internet type in your cameras DDNS address e.g. abcd12.ddns-instar.de:8081 and add the sub-directory sd - `}<code><a parentName="p" {...{
          "href": "http://abcd12.ddns-instar.de:8081/sd"
        }}>{`http://abcd12.ddns-instar.de:8081/sd`}</a></code>{`.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      